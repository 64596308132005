/**
 * 特定の要素の属性値を別の要素にコピーする関数
 * @param to コピーする元要素の情報
 * @param from コピーする対象要素の情報
 */
export default (from, to, convert) => {
    const src = from.element[from.attr];
    if (typeof src === 'string') {
        to.element.setAttribute(to.attr.toString(), convert ? convert(src) : src);
    }
};
