/**
 * HTMLの要素にタブ機能を付与するクラス
 *
 * **note**
 * このモジュールはJSが動いていない場合タブとして機能しないためa11y関連の実装はクラス内で行います。
 */
export default class TabController {
    constructor(root, config) {
        this.root = root;
        this.env = {
            selector: {
                tab: '.std-tab__item',
                tabPanel: '.std-tab__panel',
                tabList: '.std-tab__list',
            },
            ...config,
        };
        this.tab = null;
        this.tabPanel = null;
        this.tabList = null;
        this.id = `tab-${Math.floor(Math.random() * root.offsetTop * 100)}`;
    }
    init() {
        this.collectElements();
        if (!this.tabList) {
            return;
        }
        this.tabList.hidden = false;
        this.setupAttr();
        this.firstUpdate();
    }
    /**
     * 構築に必要な要素をクラス名を元に取得する処理
     * @returns stats [true]: 取得に成功 [false]: 取得に失敗
     */
    collectElements() {
        const { root, env } = this;
        this.tab = Array.from(root.querySelectorAll(env.selector.tab));
        this.tabList = root.querySelector(env.selector.tabList);
        this.tabPanel = Array.from(root.querySelectorAll(env.selector.tabPanel));
        const fragment = [this.tab, this.tabList, this.tabPanel];
        const stats = fragment.some((element) => element === null || element === []);
        // 直感に反したbooleanが帰ってきてしまうのでreturn時に修正
        return !stats;
    }
    /**
     * 必要な属性値の付与処理
     */
    setupAttr() {
        const { tab, tabList, tabPanel, id } = this;
        if (!tab) {
            return;
        }
        // roleの付与処理
        if (tab.length !== 0 && tabList && tabPanel) {
            tab.forEach((item) => {
                item.setAttribute('role', 'tab');
            });
            tabList.setAttribute('role', 'tablist');
        }
        // tab側の処理
        tab.forEach((tabItem, index) => {
            tabItem.setAttribute('aria-controls', `${id}-${index}`);
            tabItem.addEventListener('focus', (e) => {
                tab.forEach((item) => {
                    item.setAttribute('aria-selected', 'false');
                });
                if (e.target instanceof HTMLButtonElement) {
                    e.target.setAttribute('aria-selected', 'true');
                }
                if (tabItem.ariaSelected === 'true') {
                    this.callContent(`${id}-${index}`);
                }
            });
        });
        if (!tabList) {
            return;
        }
        if (tabList.children) {
            const sibling = Array.from(tabList.children);
            sibling.forEach((element) => {
                if (!(element instanceof HTMLElement)) {
                    return;
                }
                if (element.getAttribute('aria-selected') === 'true') {
                    element.tabIndex = 0;
                }
                else {
                    element.tabIndex = -1;
                }
            });
        }
        tabList.addEventListener('keydown', (e) => {
            if (e.code === 'ArrowRight') {
                // next
                const current = tabList.querySelector('[aria-selected="true"]');
                if (current?.nextElementSibling instanceof HTMLElement) {
                    current.setAttribute('aria-selected', 'false');
                    current.nextElementSibling.setAttribute('aria-selected', 'true');
                    current.tabIndex = -1;
                    current.nextElementSibling.tabIndex = 0;
                    current.nextElementSibling.focus();
                }
            }
            if (e.code === 'ArrowLeft') {
                // prev
                const current = tabList.querySelector('[aria-selected="true"]');
                if (current?.previousElementSibling instanceof HTMLElement) {
                    current.setAttribute('aria-selected', 'false');
                    current.previousElementSibling.setAttribute('aria-selected', 'true');
                    current.tabIndex = -1;
                    current.previousElementSibling.tabIndex = 0;
                    current.previousElementSibling.focus();
                }
            }
        });
        if (!tabPanel) {
            return;
        }
        // コンテンツ内部側の処理
        tabPanel.forEach((item, index) => {
            item.id = `${id}-${index}`;
            item.hidden = true;
        });
    }
    /** idを元にタブパネルを呼び出す処理 */
    callContent(name) {
        const { tab, tabPanel } = this;
        if (Array.isArray(tabPanel) && Array.isArray(tab)) {
            tabPanel.forEach((element) => {
                element.hidden = element.id !== name;
                if (element.id === name) {
                    element.setAttribute('role', 'tabpanel');
                }
                else {
                    element.removeAttribute('role');
                }
            });
        }
    }
    /** 初回のみ実行する表示処理 */
    firstUpdate() {
        const { root } = this;
        const current = root.querySelector('[aria-selected="true"]');
        const tabId = current?.getAttribute('aria-controls');
        if (tabId) {
            this.callContent(tabId);
        }
    }
}
