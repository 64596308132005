/**
 * \<review-graph\> **[Custom Element]**
 *
 * レビュー用のグラフを描画するカスタム要素です
 * @attr review-count [レビューの点数を半角スペース区切りで指定] index = "評価点"
 * @attr review-name [レビューの名前]
 */
export default class ReviewGraph extends HTMLElement {
    constructor() {
        super();
        this.renderRoot = document.createElement('div');
        this.init();
    }
    static get observedAttributes() {
        return ['review-count', 'review-name'];
    }
    attributeChangedCallback() {
        this.renderRoot.innerHTML = '';
        this.render();
    }
    init() {
        this.renderRoot.setAttribute('id', 'root');
        const shadow = this.attachShadow({ mode: 'open' });
        this.render();
        shadow.appendChild(this.renderRoot);
    }
    /**
     * this.renderRootに対して要素をバインドする処理
     */
    render() {
        const { renderRoot, reviewCount, reviewName, average } = this;
        const style = document.createElement('style');
        style.innerHTML = ReviewGraph.styles;
        /** 平均点表示のルート要素 */
        const averageView = document.createElement('div');
        averageView.setAttribute('class', 'average');
        const maxCount = Math.max(...reviewCount);
        const minorityFirst = Math.trunc((average.point - Math.trunc(average.point)) * 10);
        averageView.innerHTML = `
            <p class="average-title">${reviewName}（回答数${reviewCount.reduce((p, c) => p + c)}件）</p>
            <span class="average-view">
                <span class="average-point">${average.point}</span>
                <span class="average-par">/</span>
                <span class="average-max">${average.max}.0</span>
            </span>
            <span class="star" aria-hidden="true">${'<span class="star-int">★</span>'.repeat(average.point)}${`<span class="star-minority" style="width: .${minorityFirst}em">★</span>`}</span>
        `;
        /** グラフ表示側のルート要素 */
        const graph = document.createElement('ul');
        graph.setAttribute('class', 'review-list');
        const bar = (count, index) => `
            <li class="review-item">
                <span class="review-index">${index + 1}</span>
                <span class="review-view">
                    <div style="width:${Math.ceil((count / maxCount) * 100)}%;" class="review-bar pattern-0${index}">
                        <span class="review-count">${count}件</span>
                    </div>
                </span>
            </li>`;
        const barList = reviewCount.map((count, index) => {
            if (isNaN(count)) {
                return '';
            }
            return bar(count, index);
        });
        graph.innerHTML = barList.join('');
        this.renderRoot.appendChild(style);
        renderRoot.appendChild(averageView);
        renderRoot.appendChild(graph);
    }
    /**
     * 入力された特典から最大評価点と平均点を返却する処理
     * @ref {@link reviewCount }
     */
    get average() {
        const all = this.reviewCount.reduce((prev, current) => prev + current);
        const weight = this.reviewCount.reduce((prev, current, index) => prev + (current * (index + 1)));
        const round = Math.floor((weight / all) * 10) / 10;
        return {
            max: this.reviewCount.length,
            point: round,
        };
    }
    /** 属性値 : \[review-count\] */
    get reviewCount() {
        const points = this.getAttribute('review-count') || '';
        const convertNumbers = (data) => data.map((v) => parseInt(v, 10));
        return convertNumbers(points.split(' '));
    }
    /** 属性値 : \[review-count\] */
    set reviewCount(value) {
        if (Array.isArray(value)) {
            this.setAttribute('review-count', value.join(' '));
        }
        else {
            this.setAttribute('review-count', value);
        }
    }
    /** 属性値 : \[review-name\] */
    get reviewName() {
        return this.getAttribute('review-name') || '';
    }
    /** 属性値 : \[review-name\] */
    set reviewName(value) {
        this.setAttribute('review-name', value);
    }
}
/** コンポーネントのスタイル定義 */
ReviewGraph.styles = `
:host {font-size: inherit; width: 100%;max-width: 100%;}
[id="root"] { width: inherit; display: flex; flex-wrap: wrap; max-width: inherit; row-gap: 60px; overflow: hidden;}

.average { flex: 1 1 250px; position: relative; display: flex; flex-direction: column; justify-content: center; min-width: 48%;}
.average::before { content: ""; position: absolute; width: 100%; bottom: -30px; left: 0; border-bottom: solid 1px #707070;}
.average::after { content: "";}
.average-title { margin: 0; font-size: 1.5rem; font-weight: 500;}
.average-view { font-family: "Playfair Display", serif; display: flex; justify-content: center; align-items: baseline; column-gap: 5px;}
.average-point { font-size: 4.4rem; color: #E57645;}
.average-max { font-size: 3.13rem; color: #9399A3;}
.average-par { font-size: 3.13rem; color: #E57645; padding-right: 11px; transform: translateY(5px);}
.star { display: flex; justify-content: center; font-size: 1.75rem; color: #E57645; gap: .2em;}
.star-minority { display: inline-block; overflow: hidden;}

.review-list { margin: 0; padding: 0; list-style: none; flex: 1 1 500px; width: 100%; display: flex; justify-content: space-between; flex-direction: column-reverse; row-gap: 20px;}
.review-item { display: grid; grid-template-columns: 25px auto 70px; align-items: center; line-height: 1; height: 30px; width: 100%;}
.review-index { font-size: 1.38rem; grid-column: 1/2;}
.review-view { position: relative; grid-column: 2/3; width: 100%; height: 100%;}
.review-view::after { content: "";}
.review-bar { position: relative; height: 100%; background-color: #9399a3;}
.review-count { position: absolute; top: 50%; right: 0; padding-left: 10px; word-break: keep-all; font-size: 1.13rem; transform: translate(100%, -50%);}

.pattern-01 {background-image: url("/img/background/pattern-01.svg");}
.pattern-02 {background-image: url("/img/background/pattern-02.svg");}
.pattern-03 {background-image: url("/img/background/pattern-03.svg");}
.pattern-04 {background-image: url("/img/background/pattern-04.svg");}
@media screen and (max-width: 768px) {
.average-title { font-size: 1.38rem;}
.review-count { font-size: .95rem;}
}
`;
