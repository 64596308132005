import toCamelCase from '../util/toCamelCase';
import DisallowElements from '../modules/DisallowElements';
/**
 * ヘッダー用の処理クラス
 */
export default class HeaderController {
    /**
     * @param header 処理の起点になるheader要素
     * @param config インスタンス発行時の設定情報 {@link Config}
     */
    constructor(header, config) {
        this.header = header;
        this.env = {
            scrollResize: {
                appendClass: '--slim',
            },
            navMenu: {
                className: 'header__menu',
                dataName: 'nav-open',
            },
            ...config,
        };
        this.menuState = 'close';
        this.disallow = null;
        this.intersectionObserver = null;
        this.animationCount = 0;
    }
    /** 初回処理 */
    init() {
        if (this.env.scrollResize !== false) {
            // scrollResize有効時の処理
            this.animationCount = 0;
            this.intersectionObserver = new IntersectionObserver((entries) => this.resize(entries));
            const observeElement = document.getElementById('breadcrumb') || document.querySelector('main > *:first-child');
            if (observeElement) {
                this.intersectionObserver.observe(observeElement);
            }
        }
        if (this.env.navMenu && this.menuContent) {
            // navMenu有効化時の処理
            this.disallow = new DisallowElements(this.header);
            const { menuController, menuContent } = this;
            this.menuToggle();
            if (menuController && menuContent) {
                menuContent.style.display = this.menuState === 'close' ? 'none' : 'grid';
                menuController.addEventListener('click', () => {
                    this.menuState = this.menuState === 'close' ? 'open' : 'close';
                    this.menuToggle();
                });
            }
        }
    }
    /** スクロールを検知してヘッダーをリサイズさせる処理 */
    resize(entries) {
        /** ヘッダーが一旦隠れるアニメーション */
        const animation = () => {
            if (this.header) {
                this.animationCount++;
                if (this.animationCount === 1) {
                    // 初回描画時はアニメーションをスキップ
                    return;
                }
                this.header.animate({
                    transform: ['translateY(0)', 'translateY(-100%)', 'translateY(0)'],
                    opacity: ['1', '0', '1'],
                }, {
                    duration: 1000,
                });
            }
        };
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                animation();
                this.header.classList.remove('--slim');
            }
            else {
                animation();
                this.header.classList.add('--slim');
            }
        });
    }
    /** メニューをトグル表示させる機能 */
    menuToggle() {
        if (this.env.navMenu === false) {
            return;
        }
        const { menuController, menuContent } = this;
        if (menuContent === undefined || menuController === undefined) {
            return;
        }
        const { dataName } = this.env.navMenu;
        menuController.setAttribute('aria-expanded', this.menuState === 'open' ? 'true' : 'false');
        // スムースにアニメーションを行うための処理
        if (this.menuState === 'open') {
            // 開く場合は要素を表示してから[data-nav-open="open"]を付与
            menuContent.style.display = 'grid';
            this.disallow?.exec();
            setTimeout(() => {
                menuContent.dataset[toCamelCase(dataName)] = this.menuState;
            }, 0);
        }
        else {
            // 閉じる場合はtransitionendを受け取ってから要素を削除
            menuContent.dataset[toCamelCase(dataName)] = this.menuState;
            this.disallow?.restore();
            menuContent.addEventListener('transitionend', () => {
                if (this.menuState === 'close') {
                    menuContent.style.display = 'none';
                }
            });
        }
    }
    /** ハンバーガーメニューとなる要素 */
    get menuController() {
        if (this.env.navMenu === false) {
            return undefined;
        }
        const controller = this.header.querySelector(`.${this.env.navMenu.className}`);
        if (controller instanceof HTMLButtonElement) {
            return controller;
        }
        return undefined;
    }
    /** メニュー内部のコンテンツ */
    get menuContent() {
        if (this.env.navMenu === false) {
            return undefined;
        }
        const content = this.header.querySelector(`[data-${this.env.navMenu.dataName}]`);
        if (content instanceof HTMLElement) {
            return content;
        }
        return undefined;
    }
}
