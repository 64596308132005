/**
 * 複数の要素グループを監視してそれぞれの中から一番高い要素にサイズを合わせるクラス
 * このクラスで管理する要素の子要素に
 */
export default class HeightSynchronize {
    /**
     * @param value 対象とする`data-sync-height`の値
     * @param config インスタンス作成時の設定項目 {@link Config}
     */
    constructor(value, config) {
        /** data属性名からセレクタを作成する処理 */
        this._unitSelector = (name) => `[${this.env.dataName}="${name}"]`;
        this.units = [];
        this.values = value;
        this.env = {
            dataName: 'data-sync-height',
            interval: 500,
            enable: 'all',
            breakpoint: 767,
            ...config,
        };
        this.init();
    }
    /** 初期化処理 */
    init() {
        this._reset();
        this._reboot();
        window.addEventListener('load', () => this.syncOnce(), { once: true });
    }
    /** インスタンスを立ち上げなおす処理 */
    _reboot() {
        // 初期化
        this.units = [];
        // this.unitの取得・格納
        this.values.forEach((value) => {
            const newItem = Array.from(document.querySelectorAll(this._unitSelector(value)));
            this.units.push(newItem);
        });
    }
    /** 要素の状態を元に戻す処理 */
    _reset() {
        // 全てのheightを初期値に戻す
        this.units.forEach((unit) => {
            unit.forEach((item) => {
                item.style.height = '';
            });
        });
    }
    /** リサイズにあわせて高さを同期するメソッド */
    sync() {
        // 初回処理
        this._reset();
        const resizeHandler = () => {
            if (this.timeoutId) {
                return;
            }
            this.timeoutId = window.setTimeout(() => {
                delete this.timeoutId;
                this._reset();
                const isWide = window.matchMedia(`(min-width: ${this.env.breakpoint}px)`).matches;
                if (this.env.enable === 'all') {
                    this.syncOnce();
                }
                else if (this.env.enable === 'wide' && isWide) {
                    this.syncOnce();
                }
                else if (this.env.enable === 'narrow' && !isWide) {
                    this.syncOnce();
                }
            }, this.env.interval);
        };
        window.addEventListener('resize', resizeHandler);
    }
    /** 一度だけ高さを同期するメソッド */
    syncOnce() {
        this.units.forEach((unit) => {
            if (unit.length === 0) {
                return;
            }
            /** 要素を比較して高い方の要素を返却する関数 */
            const reducer = (prev, current) => {
                const prevHeight = prev.offsetHeight;
                const currentHeight = current.offsetHeight;
                if (prevHeight > currentHeight) {
                    return prev;
                }
                else if (currentHeight > prevHeight) {
                    return current;
                }
                return prev;
            };
            /** 一番高い要素 */
            const maxHeightElement = unit.reduce((prev, current) => reducer(prev, current));
            // 一番高い要素以外に高さを付与する処理
            unit.forEach((item) => {
                if (!(item instanceof HTMLElement)) {
                    return;
                }
                if (item === maxHeightElement) {
                    return;
                }
                item.style.height = `${maxHeightElement.offsetHeight}px`;
            });
        });
    }
}
