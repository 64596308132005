/** 外部リンクやPDFリンクなどにアイコンを付与するクラス */
export default class ExtendLinkIcon {
    constructor(element, config) {
        this.root = element;
        const ignoreClass = config?.ignoreClass || [''];
        this.env = {
            external: {
                text: '別タブで開く',
                fileName: 'external-link.svg',
            },
            pdf: {
                text: 'PDFを開く',
                fileName: 'pdf-link.svg',
            },
            ...config,
            ignoreClass: ['no-icon', ...ignoreClass],
        };
    }
    bind() {
        const { root, env } = this;
        const isIgnore = env.ignoreClass.find((value) => root.classList.contains(value));
        if (isIgnore) {
            return;
        }
        const icon = document.createElement('img');
        icon.className = 'icon';
        icon.style.display = 'inline-block';
        icon.style.verticalAlign = 'sub';
        icon.width = 25;
        icon.height = 25;
        if (root.target === '_blank' && env.external) {
            icon.src = `${ExtendLinkIcon.ICON_PATH}${env.external.fileName}`;
            icon.alt = `${env.external.text}`;
        }
        else if (/.pdf$/u.test(root.href) && env.pdf) {
            icon.src = `${ExtendLinkIcon.ICON_PATH}${env.pdf.fileName}`;
            icon.alt = env.pdf.text;
        }
        else {
            return;
        }
        root.appendChild(icon);
    }
}
ExtendLinkIcon.ICON_PATH = '/img/icon/';
