/**
 * お問い合わせページのチェックボックスにチェックを入れると
 * 下のボタンが活性化する機能を付与するクラス
 */
export default class ToggleActive {
    /**
     * @param config
     */
    constructor(config) {
        this.env = {
            checkboxClassName: '.js-check-active',
            warningTextClassName: '.js-text-active',
            rootButtonClassName: '.js-button-active',
            activeClassName: 'is-active',
            type: 'change',
            ...config,
        };
    }
    /**
     * チェックボックスのイベントを監視する処理
     */
    init() {
        const { env } = this;
        const checkBox = document.querySelector(env.checkboxClassName);
        if (!checkBox) {
            return;
        }
        checkBox.addEventListener(env.type, this.toggle());
    }
    /**
     * イベントが走った際にテキストを消したりボタンのブーリアン値を変更する処理
     */
    toggle() {
        const toggleHandler = (event) => {
            const { env } = this;
            const warningText = document.querySelector(env.warningTextClassName);
            const rootButton = document.querySelector(env.rootButtonClassName);
            if (!warningText || !rootButton) {
                return;
            }
            const button = rootButton.querySelector('button');
            if (!button) {
                return;
            }
            if (!(event.target instanceof HTMLInputElement)) {
                return;
            }
            if (event.target.checked) {
                warningText.classList.add(env.activeClassName);
                button.disabled = false;
            }
            else {
                warningText.classList.remove(env.activeClassName);
                button.disabled = true;
            }
        };
        return toggleHandler;
    }
}
