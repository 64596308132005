import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
const standard = {
    modules: [Navigation, Pagination, A11y, Autoplay],
    loop: true,
    spaceBetween: 20,
    centeredSlides: true,
    slidesPerView: 1.5,
    slidesPerGroup: 1,
    grabCursor: true,
    breakpoints: {
        768: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        900: {
            slidesPerView: 4.5,
            spaceBetween: 60,
        },
    },
    wrapperClass: 'std-slider__inner',
    slideClass: 'std-slider__slide',
    pagination: {
        el: '.std-slider__pagination',
        bulletClass: 'std-slider__bullet',
        bulletElement: 'button',
        clickable: true,
        bulletActiveClass: '--is-active',
    },
    navigation: {
        prevEl: '.std-slider__prev',
        nextEl: '.std-slider__next',
    },
    autoplay: {
        delay: 3000,
    },
    a11y: {
        firstSlideMessage: '最初のスライド',
        lastSlideMessage: '最後のスライド',
        nextSlideMessage: '次のスライドへ',
        prevSlideMessage: '前のスライドへ',
    },
};
export const config = {
    standard,
};
