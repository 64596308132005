export default class MoreShow {
    constructor(config) {
        this.env = {
            voiceBox: '[data-name="voice"]',
            moreButton: '.link-button.--primary.--triangle.align-center',
            hideClassName: 'is-none',
            eventName: 'click',
            ...config,
        };
    }
    show() {
        const { env } = this;
        const voiceElement = document.querySelectorAll(env.voiceBox);
        // お客さまの声の声が5個以下なら処理を終了
        if (voiceElement.length <= 5) {
            return;
        }
        /*
         * 5個以上なら5個から上の数分の要素を非表示化する
         * もっと見るボタンを表示させる
         */
        voiceElement.forEach((el, i) => {
            if (i >= 5) {
                el.classList.add(env.hideClassName);
            }
        });
        const moreButton = document.querySelector(env.moreButton);
        if (!moreButton) {
            return;
        }
        moreButton.classList.remove(env.hideClassName);
        /**
         * もっと見るボタンをクリックすると他の要素を表示させる
         */
        const voiceArray = Array.from(voiceElement);
        const hideBox = voiceArray.filter((value) => value.classList.contains(env.hideClassName));
        if (!hideBox) {
            return;
        }
        moreButton.addEventListener(env.eventName, () => {
            hideBox.forEach((item) => item.classList.remove(env.hideClassName));
            moreButton.classList.add(env.hideClassName);
        });
    }
}
