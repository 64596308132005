import Swiper from 'swiper';
export default class Slider {
    constructor(className, config) {
        this.config = typeof config === 'function' ? config() : config;
        this.core = new Swiper(className, this.config); // 実際に帰ってくる型定義が異なるので手動修正
    }
    /** インスタンス及びその配列に共通の処理を適用する関数 */
    static eachSwiper(target, cb) {
        if (Array.isArray(target)) {
            target.forEach((item) => cb(item));
        }
        else {
            cb(target);
        }
    }
    /** Swiperインスタンスの処理を行う関数 */
    static slideInstanceReducer(swiper, cb) {
        const stopButton = swiper.el.querySelector('.std-slider__stop');
        if (stopButton instanceof HTMLButtonElement) {
            stopButton.setAttribute('role', 'checkbox');
            stopButton.setAttribute('aria-checked', 'false');
            stopButton.innerText = '停止する';
            stopButton.addEventListener('click', (e) => cb(e, stopButton));
        }
    }
    /** 停止ボタン押下時の処理 */
    static stopHandler(instance, element) {
        if (element.getAttribute('aria-checked') === 'false') {
            instance.autoplay.stop();
            element.innerText = '再生する';
            element.setAttribute('aria-checked', 'true');
        }
        else {
            instance.autoplay.start();
            element.innerText = '停止する';
            element.setAttribute('aria-checked', 'false');
        }
    }
}
