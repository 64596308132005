import HeightSynchronize from './modules/HeightSynchronize';
import HeaderController from './modules/HeaderController';
import PictureStatic from './modules/PictureStatic';
import TabController from './modules/TabController';
new HeightSynchronize(['service-flow', 'service-list', 'service-menu', 'customer-voice', 'contact-box', 'cooking-box'], { enable: 'wide' }).sync();
const header = document.getElementById('header');
if (header) {
    const headerController = new HeaderController(header);
    headerController.init();
}
const pictures = document.querySelectorAll('.picture-static');
if (pictures.length !== 0) {
    pictures.forEach((picture) => {
        if (picture instanceof HTMLElement) {
            const pictureStatic = new PictureStatic(picture);
            pictureStatic.init();
        }
    });
}
const stdTabList = document.querySelectorAll('.std-tab');
if (stdTabList) {
    stdTabList.forEach((tab) => {
        if (tab instanceof HTMLElement) {
            const stdTab = new TabController(tab);
            stdTab.init();
        }
    });
}
import ExtendLinkIcon from './modules/ExtendLinkIcon';
(() => {
    const linkList = document.querySelectorAll('a');
    Array.from(linkList).forEach((anchor) => {
        const needIconLink = new ExtendLinkIcon(anchor);
        needIconLink.bind();
    });
})();
/** カスタム要素 */
import ReviewGraph from './modules/ReviewGraph';
(() => {
    customElements.define('review-graph', ReviewGraph);
})();
import ToggleController from './modules/ToggleController';
(() => {
    const toggleList = Array.from(document.querySelectorAll('.toggle-content-std'));
    toggleList.forEach((toggle) => {
        if (toggle instanceof HTMLElement) {
            const stdToggle = new ToggleController(toggle);
            stdToggle.init();
        }
    });
})();
import ToggleActive from './modules/ToggleActive';
(() => {
    const activeButton = new ToggleActive();
    activeButton.init();
})();
import MoreShow from './modules/MoreShow';
(() => {
    const moreShow = new MoreShow();
    moreShow.show();
})();
import * as sliderConf from './config/sliderSetting';
import Slider from './modules/Slider';
(() => {
    const SLIDE_MINIMUM = 1;
    const stdSlider = new Slider('.std-slider', sliderConf.config.standard);
    Slider.eachSwiper(stdSlider.core, (instance) => {
        // 前後にスライドを複製しているため3で割って実際のコンテンツ数に合わせる
        const slideSize = instance.slides.length / 3;
        const underThreshold = SLIDE_MINIMUM >= slideSize;
        // スライドの枚数が`SLIDE_MINIMUM`以下の場合の処理
        if (underThreshold) {
            // ループ処理を停止
            instance.loopDestroy();
            // 要素の削除系
            const controller = instance.el.querySelector('.std-slider__controller');
            const around = instance.el.querySelector('.std-slider__around');
            controller?.parentNode?.removeChild(controller);
            around?.parentNode?.removeChild(around);
            // カーソルを初期常態にリセット
            instance.unsetGrabCursor();
            // 自動再生の停止
            instance.autoplay.stop();
            // Swiperインスタンス自体の機能停止
            instance.disable();
        }
        Slider.slideInstanceReducer(instance, (_e, button) => {
            Slider.stopHandler(instance, button);
        });
    });
})();
