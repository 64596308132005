export default class ToggleController {
    /**
     * @param root モジュールのルート要素
     * @param config 設定情報
     */
    constructor(root, config) {
        this.root = root;
        this.env = {
            rootClassName: '.toggle-content-std',
            additionalCloseButton: ['__close'],
            ...config,
        };
    }
    init() {
        const { additionalCloseButton } = this;
        additionalCloseButton.forEach((element) => {
            element.addEventListener('click', () => this.close());
        });
    }
    /**
     * トグルコンテンツを閉じる処理
     */
    close() {
        const { details } = this;
        if (details) {
            details.open = false;
        }
    }
    /**
     * モジュールに含まれる`details`要素
     */
    get details() {
        const { root } = this;
        const element = root.querySelector('details');
        return element;
    }
    /**
     * 追加の閉じるボタンを格納された配列
     */
    get additionalCloseButton() {
        const { root, env } = this;
        const classNameList = env.additionalCloseButton.map((className) => `${env.rootClassName}${className}`);
        const rawElements = Array.from(root.querySelectorAll(classNameList.join(',')));
        const elements = rawElements.filter((element) => {
            if (element instanceof HTMLButtonElement) {
                return true;
            }
            else if (element instanceof HTMLInputElement) {
                return true;
            }
            else if (element instanceof HTMLAnchorElement) {
                return true;
            }
            return false;
        }); // filter関数で型の絞り込みを行っているので型を指定
        return elements;
    }
}
