import copyAttr from '../util/copyAttr';
/**
 * 縦横比固定画像の機能を構築するクラス
 */
export default class PictureStatic {
    constructor(root, config) {
        this.root = root;
        this.env = {
            waitEvent: false,
            ...config,
        };
    }
    /** 初期化メソッド */
    init() {
        const { waitEvent } = this.env;
        if (waitEvent === false) {
            this.bind();
        }
        else {
            this.root?.addEventListener(waitEvent, () => this.bind());
        }
    }
    /** 要素間の属性値コピー */
    bind() {
        const { root } = this;
        if (!root) {
            return;
        }
        const imgElement = root.querySelector('[src]');
        if (imgElement instanceof HTMLImageElement) {
            copyAttr({
                element: imgElement,
                attr: 'src',
            }, {
                element: root,
                attr: 'style',
            }, (raw) => `background-image: url('${raw}')`);
        }
    }
}
