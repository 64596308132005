export default (kebabCase) => {
    const wordList = kebabCase.split('-');
    const formatList = wordList.map((word, index) => {
        if (index === 0) {
            return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return formatList.join('');
};
