/**
 * **指定された要素以外の要素を非活性化させるクラス**
 *
 * 主にモーダルダイアログなどに使われる非活性化処理を汎用化したものです。
 */
export default class DisallowElements {
    /**
     * @param aliveElement 非活性化されない要素 [主体となるコンテンツ]
     * モーダルとして利用する場合はモーダルとして表示する要素
     * @param options 追加設定項目 {@link Config}
     */
    constructor(aliveElement, options) {
        this.alive = aliveElement;
        this.env = {
            root: document.documentElement,
            ignoreClassName: '',
            focusTarget: this.alive,
            feature: {
                focus: true,
                scroll: true,
                speak: true,
            },
            ...options,
        };
        this.focusableSelectors = [
            '[tabindex]',
            'input:not(:disabled)',
            'button:not(:disabled)',
            'textarea:not(:disabled)',
            'select:not(:disabled)',
            'a[href]',
            'video[controls]',
            'audio[controls]',
            'iframe',
        ];
        this.memory = {
            body: {
                overflow: '',
            },
            el: [],
            ariaHidden: [],
        };
        this.disallow = false;
    }
    /**
     * **初期化処理**
     *
     * 非活性化を行う前に事前に定義・実行しておきたい処理を格納
     * 外部から実行した場合データの整合性が取れなくなる場合があります。
     */
    init() {
        // メモリ内部をリセット
        this._resetMemory();
        // フラグのリセット
        this.disallow = false;
    }
    /**
     * メモリの内容をリセットする処理
     * @ref {@link Memory}
     */
    _resetMemory() {
        this.memory = {
            body: {
                overflow: '',
            },
            el: [],
            ariaHidden: [],
        };
        if (this.memory.body.focusCurrent) {
            delete this.memory.body.focusCurrent;
        }
        if (this.memory.body.focusTarget) {
            delete this.memory.body.focusTarget;
        }
        if (this.memory.body.focusTargetTabIndex) {
            delete this.memory.body.focusTargetTabIndex;
        }
    }
    /**
     * **無効化処理**
     *
     * この処理を起点に各種プライベートメソッドとして用意している処理を実行します。
     * 無効化を行った際に`disallow`フラグが変更され２回連続での実行は行なえません。
     * コンストラクタで定義した機能の有効化を上書きする場合は`feature`で指定します。
     *
     * @param focusTarget フォーカスを移動する先の要素
     * @param feature 機能の有効化フラグ [上書き用]
     * {@link Feature}
     */
    exec(focusTarget, feature) {
        if (this.disallow) {
            return;
        }
        if (feature) {
            Object.assign(this.env.feature, feature);
        }
        try {
            // Memoryを初期化
            this._resetMemory();
            if (this.env.feature.focus) {
                this._focusDisable(focusTarget);
            }
            if (this.env.feature.scroll) {
                this._scrollDisable();
            }
            if (this.env.feature.speak) {
                this._speakDisable();
            }
        }
        catch (error) {
            // エラーを検知するのに必要なコンソールログ
            // eslint-disable-next-line no-console
            console.error(error);
        }
        // 無効化フラグを変更 **必ず最後に実行**
        this.disallow = true;
    }
    /**
     * フォーカスを無効化する処理
     * @param focusTarget フォーカスを移動する先の要素
     */
    _focusDisable(focusTarget = this.env.focusTarget) {
        if (!this.focusableElements) {
            return;
        }
        // フォーカスの移動を記録
        if (document.activeElement instanceof HTMLElement) {
            this.memory.body.focusCurrent = document.activeElement;
        }
        this.memory.body.focusTarget = focusTarget;
        this.memory.body.focusTargetTabIndex = focusTarget.tabIndex;
        // メモリへの状態記憶処理
        this.focusableElements.forEach((element) => {
            /** tabIndexの値 **[属性値がない場合初期値ではなくnullを代入]** */
            const tabIndexAttr = element.hasAttribute('tabindex')
                ? element.tabIndex
                : null;
            /** 要素の状態 */
            const memoryItem = {
                current: element,
                tabIndex: tabIndexAttr,
            };
            this.memory.el.push(memoryItem);
        });
        // 整合性チェック
        if (this.memory.el.length !== this.focusableElements.length) {
            throw new Error('The process of recording the state of the element failed.');
        }
        this.focusableElements.forEach((element) => {
            element.tabIndex = -1;
        });
        // フォーカスを移動する処理
        setTimeout(() => {
            // `display:none;`が適用されている要素に対してフォーカスを移動できないので処理を遅延
            focusTarget.tabIndex = -1;
            focusTarget.focus();
        }, 1);
    }
    /** スクロールを止める処理 */
    _scrollDisable() {
        // overflowの値を記憶
        this.memory.body.overflow = this.env.root.style.overflow;
        this.env.root.style.overflow = 'hidden';
    }
    /** 読み上げさせないようにする処理 */
    _speakDisable() {
        this.env.root.ariaHidden = 'true';
        this.alive.ariaHidden = 'false';
    }
    /**
     * **状態を戻す処理**
     *
     * この処理を起点に各種プライベートメソッドとして用意されている機能を実行します。
     * 処理が完了すると`this.disallow`フラグが元に戻り、再度`this.exec()`が実行可能になります。
     */
    restore() {
        if (!this.disallow) {
            return;
        }
        try {
            if (this.env.feature.focus) {
                this._focusEnable();
            }
            if (this.env.feature.scroll) {
                this._scrollEnable();
            }
            if (this.env.feature.speak) {
                this._speakEnable();
            }
        }
        catch (error) {
            // エラーを検知するのに必要なコンソールログ
            // eslint-disable-next-line no-console
            console.error(error);
        }
        // 無効化フラグを変更 **必ず最後に実行**
        this.disallow = false;
    }
    /** フォーカスを戻す処理 */
    _focusEnable() {
        if (this.memory.body.focusCurrent instanceof HTMLElement) {
            this.memory.body.focusCurrent.focus();
        }
        this.focusableElements.forEach((element, index) => {
            const memory = this.memory.el[index].tabIndex;
            if (!memory) {
                // メモリがnull = 属性値が定義されていなかった場合は削除を行い初期状態に
                element.removeAttribute('tabindex');
            }
            else {
                element.tabIndex = memory;
            }
        });
        const focusTarget = this.memory.body?.focusTarget;
        const tabIndex = this.memory.body.focusTargetTabIndex;
        if (!focusTarget) {
            return;
        }
        if (tabIndex === focusTarget.tabIndex) {
            focusTarget.removeAttribute('tabindex');
        }
        else if (tabIndex !== undefined) {
            focusTarget.tabIndex = tabIndex;
        }
    }
    /** スクロール固定を解除する処理 [overflow値のリセット] */
    _scrollEnable() {
        // overflowの値を復元
        this.env.root.style.overflow = this.memory.body.overflow;
    }
    /** 読み上げを戻す処理 */
    _speakEnable() {
        this.alive.removeAttribute('aria-hidden');
        this.env.root.removeAttribute('aria-hidden');
    }
    /**
     * 非活性化するべき要素
     */
    get focusableElements() {
        const connecter = this.env.ignoreClassName !== ''
            ? `:not(.${this.env.ignoreClassName}),`
            : ',';
        const matchElements = [
            ...this.env.root.querySelectorAll(this.focusableSelectors.join(connecter)),
        ];
        const outerFocusableElements = [...matchElements].
            // this.alive は除外
            filter((v) => v !== this.alive).
            // this.alive 内部は除外
            filter((value) => !this.innerContent.includes(value)).
            // フォーカスの移動先はtabIndexを振るため除外
            filter((v) => v !== this.env.focusTarget);
        return outerFocusableElements;
    }
    /** `this.alive` 内部の要素すべて */
    get innerContent() {
        return [...this.alive.querySelectorAll('*')];
    }
}
